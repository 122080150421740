* {
  padding: 0;
  margin: 0;
}

html {
  min-width: 375px;
  font-size: 62.5%;

  @include mq(md) {
    min-width: 1280px;
  }
}

body {
  font-family: 'noto-sans-cjk-jp', 'Hiragino Kaku Gothic ProN',
    'ヒラギノ角ゴ ProN', 'MS PGothic', 'MS Ｐゴシック', sans-serif;
  font-size: 1.3rem;
  color: #333;
  letter-spacing: 0.05em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: #000;

  @include mq(md) {
    font-size: 1.6rem;
  }
}

ol,
ul {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg {
  &:not([fill]) {
    fill: currentColor;
  }
}

table {
  border-collapse: collapse;
}

[data-whatinput='mouse'] {
  :focus {
    outline: none;
  }
}
